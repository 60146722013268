import React from 'react'
import ReactHtmlParser from 'react-html-parser';
import PopupVideo from "../components/popupVideo";

const LandingTestimonialSliderMobile = ({props, landingTestimonials}) => {

  


    return (

      
      <div class="col-md-12 "> 
      <div class=" swiper-container lp-testimonials-box newStyle">
      <div className="swiper-wrapper"> 
      <div key="0" class="swiper-slide">
          <div class="lp-testimonial-box bg-orng h-100">
          <div class="lp-scholership"> 
          {landingTestimonials[0].scholership_image && (
  <img
    src={landingTestimonials[0].scholership_image.source_url}
    alt="Scholership"
    className="lp-testimonial-scholership-img"
  />
)}

                              </div>

              <div class="lp-testimonial-head">
                  <img src={landingTestimonials[0].testimonial_image.source_url} alt={landingTestimonials[0].candidate_name}  class="lp-testimonial-head-img" />
                  <div class="lp-testimonial-head-detail">
                      <p class="mb-0 font-weight-500">{landingTestimonials[0].candidate_name}</p>
                      <p class="mb-2 font-weight-500">{landingTestimonials[0].location}</p> 
                      <p class="mb-0">{landingTestimonials[0].batch_detail}</p> 
                      <p class="mb-0">{landingTestimonials[0].program_name}</p> 
                  </div>
              </div>
             
              <img src={landingTestimonials[0].country_flag?.source_url} alt="Flag" class="lp-testimonial-qoute" width="48" height="32"/>
       
              <div class="lp-testimonial-detail">
                  
                       
                  <p>{ReactHtmlParser(landingTestimonials[0].testimonial_content)}</p>
                  
                  {landingTestimonials[0].youtube_video_link && landingTestimonials[0].youtube_video_link !== '#' && (
                                  <div class="lp-testimonial-date">
                                    <PopupVideo time="9999999999" videoURL={landingTestimonials[0].youtube_video_link} designType="New" />
                                    
                                  </div>
                                )}
              </div>
          </div>
          </div>
          <div key="1" class="swiper-slide">
          <div class="lp-testimonial-box bg-blu h-100">
          <div class="lp-scholership"> 
          {landingTestimonials[1].scholership_image && (
  <img
    src={landingTestimonials[1].scholership_image.source_url}
    alt="Scholership"
    className="lp-testimonial-scholership-img"
  />
)}

                              </div>

              <div class="lp-testimonial-head">
                  <img src={landingTestimonials[1].testimonial_image.source_url} alt={landingTestimonials[1].candidate_name}  class="lp-testimonial-head-img" />
                  <div class="lp-testimonial-head-detail">
                      <p class="mb-0 font-weight-500">{landingTestimonials[1].candidate_name}</p>
                      <p class="mb-2 font-weight-500">{landingTestimonials[1].location}</p> 
                      <p class="mb-0">{landingTestimonials[1].batch_detail}</p> 
                      <p class="mb-0">{landingTestimonials[1].program_name}</p> 
                  </div>
              </div>
             
              <img src={landingTestimonials[1].country_flag?.source_url} alt="Flag" class="lp-testimonial-qoute" />
       
              <div class="lp-testimonial-detail">
                  
                       
                  <p>{ReactHtmlParser(landingTestimonials[1].testimonial_content)}</p>
                  
                  {landingTestimonials[1].youtube_video_link && landingTestimonials[1].youtube_video_link !== '#' && (
                                  <div class="lp-testimonial-date">
                                    <PopupVideo time="9999999999" videoURL={landingTestimonials[1].youtube_video_link} designType="New" />
                                    
                                  </div>
                                )}
              </div>
          </div>
          </div>

          {landingTestimonials[2] && (
  <div key="2" class="swiper-slide">
    <div class="lp-testimonial-box bg-yelw h-100">
      <div class="lp-scholership"> 
      {landingTestimonials[2].scholership_image && (
  <img
    src={landingTestimonials[2].scholership_image.source_url}
    alt="Scholership"
    className="lp-testimonial-scholership-img"
  />
)}

      </div>

      <div class="lp-testimonial-head">
        <img src={landingTestimonials[2].testimonial_image.source_url} alt={landingTestimonials[2].candidate_name}  class="lp-testimonial-head-img" />
        <div class="lp-testimonial-head-detail">
          <p class="mb-0 font-weight-500">{landingTestimonials[2].candidate_name}</p>
          <p class="mb-2 font-weight-500">{landingTestimonials[2].location}</p> 
          <p class="mb-0">{landingTestimonials[2].batch_detail}</p> 
          <p class="mb-0">{landingTestimonials[2].program_name}</p> 
        </div>
      </div>
             
      <img src={landingTestimonials[2].country_flag?.source_url} alt="Flag" class="lp-testimonial-qoute" width="48" height="32" />
       
      <div class="lp-testimonial-detail">                  
        <p>{ReactHtmlParser(landingTestimonials[2].testimonial_content)}</p>
                  
        {landingTestimonials[2].youtube_video_link && landingTestimonials[2].youtube_video_link !== '#' && (
          <div class="lp-testimonial-date">
            <PopupVideo time="9999999999" videoURL={landingTestimonials[2].youtube_video_link} designType="New" />
          </div>
        )}
      </div>
    </div>
  </div>
)}


        

</div>
     
      
      <div className="swiper-pagination swiper-pagination-testimonial"></div>
      </div>



  </div>
    )
}

export default LandingTestimonialSliderMobile
