import React, { Component, useEffect } from 'react';
import Swiper from 'swiper';
import ArrowRightSolidIcon from '../images/icons/arrow-right-solid.svg';
import ArrowLeftSolidIcon from '../images/icons/arrow-left-solid.svg';
import ProgramInfoCommon from './program-listing-info-common-ug';

const ProgramInfoCommonList = (node, isMobile) => {
  return (
    <ProgramInfoCommon
      DetailPageUrl={node.url}
      Target={'top'}
      UniversityImage={
        (node.isMobile && node.program_banner_image_mobile) ? 
        `${node.program_banner_image_mobile.source_url}?mobile` : 
        node.degree_banner?.source_url
      }
      
     UniversityRank={node.college_university_name}
      UniversityLogo={node.college_university_logo.source_url}
      ProgramName={node.degree_name}
      PogramDuration={''}
      Country={node.country_name.trim()}
      UniversityName={''}
      ShortEligibility={''}
      YouSave={''}
      Term1Info={''}
      Term2Info={''}
      Term3Info={''}
      Term1Title={''}
      Term2Title={''}
      Term3Title={''}
      isFeatured={''}
      featuredOrder={''}
      wordpress_id={''}
    />
  );
};

const PopularDegreeOutcome = (props) => {
  const { degrees, isMobile } = props;

  

  useEffect(() => {

    var swiper = new Swiper('.degree-slide', {
      centeredSlides: false,
      loop: false,
      slidesPerView: 1,
      watchOverflow: true,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination-degree',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-next-degree',
        prevEl: '.swiper-prev-degree',
      },
      breakpoints: {
        990: { slidesPerGroup: 3, slidesPerView: 3 },
        768: { slidesPerGroup: 2, slidesPerView: 2 },
        576: { slidesPerGroup: 1, slidesPerView: 1 },
      },
    });
  }, [isMobile]); 

  const programList = degrees.map((node) => ({
    ...node,
    isMobile: isMobile,
  }));

  return (
    <div className="col-12 justify-content-center">
      <div className="degree-slide swiper-container swiper-style-new-design">
        <div className="swiper-wrapper">
          {programList &&
            programList.length > 0 &&
            programList.map((node, index) => (
              <React.Fragment key={node.id}>
                <div className="swiper-slide ">
                  <div className="col-sm-12 col-12 col-lg-12 program-box-wrapper margin-20px-bottom padding-25px-lr">
                    {ProgramInfoCommonList(node, node.isMobile)}
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
        <div className="swiper-pagination swiper-pagination-degree"></div>
        <div className="swiper-button-next swiper-next-degree rounded-circle light slider-navigation-style-07 box-shadow-double-large">
          <img
            src={ArrowRightSolidIcon}
            alt="->"
            className="left-icon newicon newicon-black left2pxtop0px"
          />
        </div>
        <div className="swiper-button-prev swiper-prev-degree rounded-circle light slider-navigation-style-07 box-shadow-double-large">
          <img
            src={ArrowLeftSolidIcon}
            alt="<-"
            className="left-icon newicon newicon-black left2pxtop0px"
          />
        </div>
      </div>
    </div>
  );
};

export default PopularDegreeOutcome;
